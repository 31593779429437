import { Button } from '@mui/material';
import { Box } from '@mui/system';
import XMLViewer from 'react-xml-viewer';
import useCopyTextToClipboard from 'utils/hooks/useCopyTextToClipboard';
import { formatXML } from 'utils/xml/utils';
import { FaRegCopy } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { Icon } from 'components/ScrIcon/components';
type Props = {
  melding: string;
  copyButton?: boolean;
  formatCopyContent?: boolean;
};

const XmlResult = ({
  melding,
  copyButton = true,
  formatCopyContent = false,
}: Props) => {
  const [copyXml] = useCopyTextToClipboard();
  return (
    <Box>
      {copyButton && (
        <Box sx={{ textAlign: 'left', marginBottom: 1 }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() =>
              copyXml(formatCopyContent ? formatXML(melding) : melding)
            }>
            <Icon size="small" Icon={FaRegCopy as IconType} />
            <Box sx={{ marginX: 1 }}>Kopier XML</Box>
          </Button>
        </Box>
      )}
      <XMLViewer xml={melding} />
    </Box>
  );
};
export default XmlResult;
