import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DisplayError } from 'components';
import { ErrorTypeEnum } from 'components/Error/interface';
import { fetchCurrentBruker } from 'features/currentBruker';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { useAppDispatch, useAppSelector } from './hooks';
import { RootState } from './rootReducer';
import AuthRoute from './routes/AuthRoute';
import { router } from './routes/Routes';
import { getAppInfo } from './selectors';
import GlobalCss from './styles';

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isAuthed, setIsAuthed] = useState<boolean>();

  const isLoggetUtContext = window.location.pathname === '/logget-ut';

  const { hasHelseIdToken, loadingComplete, isLoading } = useAppSelector(
    (state: RootState) => getAppInfo(state)
  );

  useEffect(() => {
    if (!isLoading) {
      if (!isLoggetUtContext && !loadingComplete)
        dispatch(fetchCurrentBruker());
      else if (isAuthed === undefined) setIsAuthed(hasHelseIdToken);
    }
  }, [
    dispatch,
    loadingComplete,
    isLoading,
    isLoggetUtContext,
    isAuthed,
    hasHelseIdToken,
  ]);

  if (isLoggetUtContext && isAuthed === false)
    return <DisplayError errorType={ErrorTypeEnum.LoggedOut} />;

  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <GlobalCss />
          <RouterProvider
            router={router}
            fallbackElement={
              <AuthRoute>
                <DisplayError errorType={ErrorTypeEnum.NotCreated} />
              </AuthRoute>
            }
          />
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
};

export default App;
