export const isValidXML = (xmlString: string) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
    const parserError = xmlDoc.querySelector('parsererror');
    return !parserError;
  } catch (e) {
    return false;
  }
};

export const formatXML = (xmlString: string, indentSize: number = 2) => {
  try {
    // Parse the XML string into a DOM
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');

    // Check for parsing errors
    const parseError = xmlDoc.getElementsByTagName('parsererror');
    if (parseError.length > 0) {
      throw new Error('Invalid XML: ' + parseError[0].textContent);
    }

    // Capture XML declaration if present
    let xmlDeclaration = '';
    const declarationMatch = xmlString.match(/^<\?xml[^>]+?>/);
    if (declarationMatch) {
      xmlDeclaration = declarationMatch[0];
    }

    /**
     * Recursively formats XML nodes with proper indentation.
     * Ensures tags with only text content remain on a single line.
     * @param node - The current XML node.
     * @param level - The current level of indentation.
     * @returns The formatted XML string for the node.
     */
    const formatNode = (node: Node, level: number): string => {
      const indent = ' '.repeat(indentSize * level);
      let result = '';

      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as Element;
        const tagName = element.tagName;

        // Open tag with attributes
        result += `${indent}<${tagName}`;
        if (element.attributes.length > 0) {
          for (let i = 0; i < element.attributes.length; i++) {
            const attr = element.attributes[i];
            result += ` ${attr.name}="${attr.value}"`;
          }
        }

        // Check child nodes
        if (
          element.childNodes.length === 1 &&
          element.childNodes[0].nodeType === Node.TEXT_NODE
        ) {
          // Inline format if the element contains only text
          const textContent = element.textContent?.trim();
          result += `>${textContent}</${tagName}>\n`;
        } else if (element.childNodes.length > 0) {
          // Multiline format if the element has child nodes
          result += '>\n';
          for (let i = 0; i < element.childNodes.length; i++) {
            result += formatNode(element.childNodes[i], level + 1);
          }
          result += `${indent}</${tagName}>\n`;
        } else {
          // Self-closing tag for empty elements
          result += ' />\n';
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        // Handle text nodes (ignore pure whitespace text)
        const textContent = node.textContent?.trim();
        if (textContent) {
          result += `${indent}${textContent}\n`;
        }
      }

      return result;
    };

    // Process all root nodes
    let formattedXml = '';
    for (let i = 0; i < xmlDoc.childNodes.length; i++) {
      formattedXml += formatNode(xmlDoc.childNodes[i], 0);
    }

    // Add XML declaration at the top if present
    if (xmlDeclaration) {
      formattedXml = xmlDeclaration + '\n' + formattedXml.trim();
    }

    return formattedXml.trim();
  } catch (error) {
    return xmlString;
  }
};
