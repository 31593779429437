import { Grid, Typography } from '@mui/material';
import { ScrWrapper } from 'components';
import { FC, ReactNode } from 'react';

import { ResultType } from './interface';
import JsonResult from './JsonResult';
import XmlResult from './XmlResult';

type Props = {
  melding: string;
  children?: ReactNode;
  type?: string;
  copyButton?: boolean;
  formatCopyContent?: boolean;
};

const ResultArea: FC<Props> = ({
  melding,
  children,
  type = ResultType.XML,
  copyButton,
  formatCopyContent,
}) => {
  return (
    <ScrWrapper>
      <Grid container padding={2} sx={{ overflow: 'auto' }}>
        <Grid item xs={12}>
          {children}
        </Grid>
        <Grid item xs={12}>
          {type === ResultType.XML && (
            <XmlResult
              melding={melding}
              copyButton={copyButton}
              formatCopyContent={formatCopyContent}
            />
          )}
          {type === ResultType.JSON && (
            <JsonResult
              melding={melding}
              copyButton={copyButton}
              formatCopyContent={formatCopyContent}
            />
          )}
          {type === ResultType.TEXT && (
            <Typography
              component="pre"
              lang="xml"
              sx={{
                backgroundColor: (theme) => theme.palette.scr.blue50,
                overflow: 'auto',
                p: 2,
                whiteSpace: 'pre-wrap',
              }}>
              {melding}
            </Typography>
          )}
        </Grid>
      </Grid>
    </ScrWrapper>
  );
};

export default ResultArea;
