import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { JSONTree } from 'react-json-tree';
import useCopyTextToClipboard from 'utils/hooks/useCopyTextToClipboard';
import { formatJson } from 'utils/json/utils';
import { Icon } from 'components/ScrIcon/components';
import { FaRegCopy } from 'react-icons/fa';
import { IconType } from 'react-icons';
type Props = {
  melding: string;
  copyButton?: boolean;
  formatCopyContent?: boolean;
};

const theme = {
  scheme: 'bright',
  author: 'built-in',
  base00: '#ffffff',
  base01: '#00000008',
  base02: '#00ffff',
  base03: '#ff00ff',
  base04: '#00000038',
  base05: '#ff0000',
  base06: '#0000ff',
  base07: '#00ff00',
  base08: '#000000',
  base09: '#000000',
  base0A: '#000000',
};

const JsonResult = ({
  melding,
  copyButton = true,
  formatCopyContent = false,
}: Props) => {
  const [copyJson] = useCopyTextToClipboard();

  return (
    <Box>
      {copyButton && (
        <Box sx={{ textAlign: 'left' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() =>
              copyJson(formatCopyContent ? formatJson(melding) : melding)
            }>
            <Icon size="small" Icon={FaRegCopy as IconType} />
            <Box sx={{ marginX: 1 }}>Kopier JSON</Box>
          </Button>
        </Box>
      )}
      <JSONTree
        data={JSON.parse(melding)}
        theme={theme}
        collectionLimit={100}
      />
    </Box>
  );
};
export default JsonResult;
